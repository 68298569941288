import { isExpired } from "react-jwt";
import crypto from "crypto-js";

import { Log } from './general';
import { post } from "../api-services/fetch";
import paths from "../api-services/endpoints";

/**
 * This function encodes its payload into a token
 * @param {string} name the name that will be stored in the localStorage
 * @param {object || string} payload the value to be encoded
 */
export const encodeToken = (name, payload) => {
  const encodedToken = crypto.AES.encrypt(
    JSON.stringify({ payload }),
    process.env.REACT_APP_JWT_SECRET
  ).toString();
  window.localStorage.setItem(name, encodedToken);
};

/**
 * this function decodes the token value for the name supplied if it exists
 * @param {string} name the name of the token to be decoded
 * @param {string} path path url that will be redirected to if the decoding process fails
 * @param {boolean} logoutUser indicates if the user should be taken back to the path page or not
 */
export const decodeToken = (name, path = '/login', logoutUser = true) => {
  try {
    const item = window.localStorage.getItem(name);
    if (!item) {
      logoutUser && logout(path);
      return null;
    }
    // return jwt.verify(item, process.env.REACT_APP_JWT_SECRET);
    const decodedToken = crypto.AES.decrypt(
      item,
      process.env.REACT_APP_JWT_SECRET,
    ).toString(crypto.enc.Utf8);
    return JSON.parse(decodedToken).payload;
  } catch (err) {
    Log(err);
    logoutUser && logout(path);
  }
  return null;
};

/**
 * this function decodes access token
 * @param {string} name the name of the token to be decoded
 * @param {string} path path url that will be redirected to if the decoding process fails
 * @param {boolean} logoutUser indicates if the user should be taken back to the path page or not
 */
export const decodeAccessToken = (name = 'ta', path = '/login', logoutUser = true) => {
  try {
    const item = decodeToken(name);
    const expired = isExpired(item);
   
    return !expired ? item : logoutUser && logout(path);
  } catch (err) {
    Log(err);
    logoutUser && logout(path);
  }
  return null;
};

export const logout = (path = '/login', isExpiredSession) => {
  post({
    endpoint: paths.logout
  });
  
  setTimeout(() => {
    localStorage.clear();
    isExpiredSession && localStorage.setItem('se', true);
    window.location.assign(path);
  }, 2000);
};

export const refreshToken = (path = '/login', isExpiredSession) => {
  post({
    endpoint: paths.refreshToken
  });
};

// export const encodeValue = (name, payload) => {
//   const signed = jwt.sign(payload, name);
//   window.localStorage.setItem(name, signed);
// };

// export const decodeValue = (name) => {
//   const item = window.localStorage.getItem(name);
//   jwt.decode(item, name);
// };

// /**
//  * This function hashes a password and returns the hash in hex format.
//  * @param {string} password the password that you want to hash
//  * @returns {string} string value of the hash in hex format
//  */
// export const hashPassword = (password) => crypto
//   .createHash('sha512').update(password).digest('hex');

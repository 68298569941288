import React from 'react';
import { Link } from 'react-router-dom';
import privacySideBarLinks from '../../data/privacyPolicy';

const PrivacyPolicySidebar = ({ active = '#general-definitions' }) => (
  <div>
    <h3 className="mb-4 font-bold">
      <strong>Table of Content:</strong>
    </h3>
    <ul className="text-alat-dimGrey font-openSans font-normal">
      {
        privacySideBarLinks.map((link) => (
          <li key={link.title} className={`${active === link.link ? 'border-l-4 border-alat-light text-alat-light' : ''} py-3 px-6 hover:text-alat-light`}>
            <Link to={link.link}>{link.title}</Link>
          </li>
        ))
      }
    </ul>
  </div>
);

export default PrivacyPolicySidebar;

/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import PrivacyPolicySidebar from "../../common/PrivacyPolicySidebar";

const PrivacyPolicy = ({ isModal = false }) => {
  const [currentSectionInView, setCurrentSectionInView] = useState(
    "#general-definitions"
  );

  const { hash } = useLocation();
  const generalRef = useRef();
  const cookiesRef = useRef();
  const internetCommunicationsRef = useRef();
  const yourRightsRef = useRef();
  const contactingYouRef = useRef();

  const allRefs = [
    generalRef,
    cookiesRef,
    internetCommunicationsRef,
    yourRightsRef,
    contactingYouRef
  ];

  useEffect(() => {
    document.title = "ALATpay - Terms and Conditions";
    scrollToSection();
  }, [hash]);

  const scrollToSection = () => {
    if (window.location.hash === "#general-definitions") {
      generalRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.hash === "#cookies") {
      cookiesRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.hash === "#internet-communications") {
      internetCommunicationsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.hash === "#your-rights") {
      yourRightsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.hash === "#contacting-you") {
      contactingYouRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setCurrentSectionInView(entry.target.id);
        // if (entry.intersectionRatio >= 0.75) {

        // }
      }
    });
  };

  const observerOptions = useMemo(
    () => ({
      root: null,
      rootMargin: "0px",
      threshold: 0.2
    }),
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    allRefs.forEach((ref) => {
      observer.observe(ref.current);
    });

    return () => {
      observer.disconnect();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [observerOptions]);

  return (
    <>
      {!isModal && <Navbar />}

      <>
        <div
          className="font-inter max-w-[1440px] px-5 md:px-10 pb-10 relative"
          suppressHydrationWarning={true}
        >
          <section className="h-60 max-h-60 min-h-full bg-[#FCF3F4] flex text-center justify-center items-center">
            <div className="space-y-2">
              <h1 className="text-alat-termsBlack font-bold text-2xl">
                Privacy Policy
              </h1>
              <p className="text-alat-termsBlack text-base font-normal"></p>
            </div>
          </section>
          <section className="container mx-auto mt-16 md:flex px-2 text-left text-alat-termsDeepBlack max-w-7xl">
            <div className="w-1/4 hidden md:block min-h-screen transition-all duration-500 overflow-hidden">
              <div className={``}>
                <PrivacyPolicySidebar
                  active={currentSectionInView}
                  links="privacyPolicy"
                />
              </div>
            </div>

            <section className="w-full md:w-3/4 space-y-10 text-base">
              {/* General Definitions */}
              <div
                className="space-y-4"
                ref={generalRef}
                id="#general-definitions"
              >
                <h2 className="text-alat-termsDeepBlack font-bold text-2xl">
                  1. Your Personal Information
                </h2>
                <div className="space-y-4">
                  <p>
                    This Data Protection and Privacy Statement relates solely to
                    information supplied by you on this Web Site. The
                    Data Controller (“ALATPay”) respects the privacy of your
                    personal information and will treat it confidentially and
                    securely.
                  </p>

                  <p>
                    Any personal information provided by you to ALATPay through
                    this Web Site will be used for the purpose of providing and
                    operating the products and services you have requested at
                    this Web Site and for other related purposes. These may
                    include updating and enhancing ALATPay records,
                    understanding your financial needs, conducting credit
                    checks, reviewing credit worthiness, assisting other
                    financial institutions to conduct credit checks, advising
                    you of other products and services which may be of interest
                    to you, crime/fraud prevention and debt collection purposes,
                    purposes required by law or regulation, and planning,
                    conducting, and monitoring ALATPAY’s business. The
                    information collected from you by ALATPay will be valuable
                    in improving the design and marketing of our range of
                    services and related products for customer use.
                  </p>

                  <p>
                    If you are providing your details in connection with an
                    application for employment, please refer to our “Data
                    Protection and Privacy Statement – Job Applicants” which
                    will be displayed when you apply (see our ‘Careers’ page).
                    This Policy will not alter or affect any information
                    otherwise provided by you to ALATPay.
                  </p>

                  <p>
                    Other than to those individuals and entities listed below,
                    your details will not be revealed by ALATPay to any external
                    body unless ALATPay has your permission or is under either a
                    legal obligation or any other duty to do so. For the
                    purposes detailed above, your information may be disclosed
                    to:
                  </p>

                  <ul>
                    <li>
                      Other Branches or Companies in the Wema Group (i.e.,
                      ALATPay, its subsidiaries, and affiliates).
                    </li>
                    <li>
                      Any regulatory, supervisory, governmental, or
                      quasi-governmental authority with jurisdiction over Wema
                      Group members.
                    </li>
                    <li>
                      Any agent, contractor, or third-party service provider,
                      professional adviser, or any other person under a duty of
                      confidentiality to the Wema Group.
                    </li>
                    <li>
                      Credit reference agencies and, in the event of default,
                      debt collection agencies.
                    </li>
                    <li>
                      Any actual or potential participant or sub-participant in,
                      assignee, novatee or transferee of any of the Wema Group’s
                      rights and/or obligations in relation to you.
                    </li>
                    <li>
                      Any financial institution with which ALATPay has or
                      proposes to have dealings.
                    </li>
                  </ul>

                  <p>
                    The above disclosures may require the transfer of your
                    information to parties located in countries that do not
                    offer the same level of data protection as your home
                    country. However, ALATPay will ensure that parties to whom
                    your details are transferred treat your information securely
                    and confidentially. ALATPay also pledges its intention fully
                    to meet any internationally recognized standards of personal
                    data privacy protection and to comply with applicable data
                    protection and privacy laws. We may transfer your
                    information if it is necessary to perform our contract with
                    you and by providing details to ALATPay via this Web Site
                    you are deemed to consent to any other transfers.
                  </p>

                  <p>
                    Information held about you is retained if the purpose for
                    which the information was collected continues. The
                    information is then destroyed unless its retention is
                    required to satisfy legal, regulatory, or accounting
                    requirements or to protect ALATPay interests. Please note
                    that regulations may require the Bank to retain your
                    personal data for a specified period even after the end of
                    your banking relationship with us. It is your responsibility
                    to maintain the secrecy of any user ID and login password
                    you hold.
                  </p>
                </div>
              </div>

              {/* The Service */}
              <div className="space-y-4" ref={cookiesRef} id="#cookies">
                <h2 className="text-alat-termsDeepBlack font-bold text-2xl">
                  2. Cookies
                </h2>
                <div className="space-y-4">
                  <p>
                    In order to improve our Internet service to you, we will
                    occasionally use a “cookie” and/or other similar files or
                    programs which may place certain information on your
                    computer’s hard drive when you visit any ALATPay web site.
                  </p>

                  <p>
                    A cookie is a small amount of data that our web server sends
                    to your web browser when you visit certain parts of our
                    site. We may use cookies to:
                  </p>

                  <ul>
                    <li>
                      Allow us to recognize the PC you are using when you return
                      to our web site so that we can understand your interest in
                      our web site and tailor its content and advertisements to
                      match your interests (This type of cookie may be stored
                      permanently on your PC but does not contain any
                      information that can identify you personally).
                    </li>
                    <li>
                      Identify you after you have logged in by storing a
                      temporary reference number in the cookie so that our web
                      server can conduct a dialogue with you while
                      simultaneously dealing with other customers. (Your browser
                      keeps this type of cookie until you log off or close down
                      your browser when these types of cookie are normally
                      deleted. No other information is stored in this type of
                      cookie).
                    </li>
                    <li>
                      Allow you to carry information across pages of our site
                      and avoid having to re-enter that information.
                    </li>
                    <li>
                      Allow you access to stored information if you register for
                      any of our on-line services.
                    </li>
                    <li>
                      Enable us to produce statistical information (anonymous)
                      which helps us to improve the structure and content of our
                      web site.
                    </li>
                    <li>
                      Enable us to evaluate the effectiveness of our advertising
                      and promotions.
                    </li>
                  </ul>

                  <p>
                    Cookies do not enable us to gather personal information
                    about you unless you give the information to our server.
                    Most Internet browser software allows the blocking of all
                    cookies or enables you to receive a warning before a cookie
                    is stored. For further information, please refer to your
                    Internet browser software instructions or help screen.
                    Alternatively, information on deleting or controlling
                    cookies is available at{" "}
                    <a href="http://www.allaboutcookies.org">
                      http://www.allaboutcookies.org
                    </a>
                    .
                  </p>
                </div>
              </div>

              {/* The Service */}
              <div
                className="space-y-4"
                ref={internetCommunicationsRef}
                id="#internet-communications"
              >
                <h2 className="text-alat-termsDeepBlack font-bold text-2xl">
                  3. Internet Communications
                </h2>
                <div className="space-y-4">
                  <p>
                    In order to maintain the security of our systems, protect
                    our staff, record transactions, and, in certain
                    circumstances, to prevent and detect crime or unauthorised
                    activities, ALATPay reserves the right to monitor all
                    internet communications including web and email traffic into
                    and out of its domains.
                  </p>
                </div>
              </div>

              {/* The Service */}
              <div className="space-y-4" ref={yourRightsRef} id="#your-rights">
                <h2 className="text-alat-termsDeepBlack font-bold text-2xl">
                  4. Your Rights and How To Contact Us
                </h2>
                <div className="space-y-4">
                  <p>
                    In order to maintain the security of our systems, protect
                    our staff, record transactions, and, in certain
                    circumstances, to prevent and detect crime or unauthorised
                    activities, ALATPay reserves the right to monitor all
                    internet communications including web and email traffic into
                    and out of its domains.
                  </p>
                </div>
              </div>

              {/* The Service */}
              <div
                className="space-y-4"
                ref={contactingYouRef}
                id="#your-rights"
              >
                <h2 className="text-alat-termsDeepBlack font-bold text-2xl">
                  5. Contacting You
                </h2>
                <div className="space-y-4">
                  <p>
                    In providing your telephone, facsimile number, postal and
                    e-mail address or similar details, you agree that ALATPay
                    may contact you by these methods to keep you informed about
                    ALATPay products and services or for any other reason. If
                    you prefer not to be kept informed of ALATPay products and
                    services, please contact ALATPay by E-mail.
                  </p>

                  <p>
                    ALATPay reserves the right to amend its prevailing Data
                    Protection and Privacy Statement at any time and will place
                    any such amendments on this Web Site. This Data Protection
                    and Privacy Statement is not intended to, nor does it,
                    create any contractual rights whatsoever or any other legal
                    rights, nor does it create any obligations on ALATPay in
                    respect of any other party or on behalf of any party.
                  </p>

                  {/* <p>
                                    Download our full Privacy Policy document <a href="[INSERT PRIVACY POLICY DOCUMENT URL]">here</a>.
                                </p> */}

                  <p>COPYRIGHT © ALATPay. ALL RIGHTS RESERVED.</p>
                </div>
              </div>
            </section>
          </section>
        </div>
      </>

      {!isModal && <Footer />}
    </>
  );
};

export default PrivacyPolicy;

import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { MdHelpOutline } from 'react-icons/md';
import clsx from 'clsx';
import { inputMode } from '../../utilities/general';

const TextInput = ({
  className, name, label, type = 'text', value,
  onChange, readOnly, disabled, onBlur, onKeyDown, forgotPassword = null,
  onKeyPress, minDate, maxDate, maxLength, placeholder, help, pattern, labelClassName="",
  hasError, error="", disabledStyle, setInputMode
}) => {
  const [inputType, setInputType] = useState(type);
  const _inputMode = setInputMode ?? inputMode(type);

  return (
    <div className={`${className || ''} form-group relative`}>
      <label htmlFor={name} className="text-sm block mb-2 flex items-center justify-between">
        <span className={`flex items-center ${labelClassName}`}>
          {label}
          {help && (
            <>
              <MdHelpOutline
                className="ml-1 text-lg cursor-pointer"
                data-tip
                data-for={`${name}-help`}
              />
              <ReactTooltip className="w-80" id={`${name}-help`} html type="info" effect="solid">{help}</ReactTooltip>
            </>
          )}
        </span>
        {forgotPassword && (
          <span
            className="text-alat-light cursor-pointer hover:underline"
            onClick={() => forgotPassword()}
            role="presentation"
          >
            Forgot Password?
          </span>
        )}
      </label>
      <input
        type={inputType}
        inputMode={_inputMode}
        name={name}
        id={name}
        value={value}
        className={clsx(
          "shadow appearance-none border rounded h-11 w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-alat-light bg-white",
          hasError ? "border-alat-light" : "border-gray-300",
          disabled ? disabledStyle : ""
        )}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabled}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        min={minDate}
        max={maxDate}
        maxLength={maxLength}
        autoComplete="off"
        placeholder={placeholder || ''}
        pattern={pattern}
        data-testid={name || label || ''}
      />
      {
        hasError && <span className="text-red-500 text-[14px]">{error}</span>
      }
      {type === 'password' && (
        <span className={`absolute right-3 text-gray-700 ${hasError ? "bottom-8" : "bottom-3"}`}>
          {inputType === 'password' ? (
            <AiFillEye className="w-5 h-auto cursor-pointer" onClick={() => setInputType('text')} />
          ) : (
            <AiFillEyeInvisible className="w-5 h-auto cursor-pointer" onClick={() => setInputType('password')} />
          )}
        </span>
      )}
    </div>
  );
};

export default TextInput;

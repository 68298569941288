import React, { useState, useEffect } from "react";

const OtpRateLimiter = ({
  setDisableSend = () => {},
  resendOtp = () => {},
  text = "Resend OTP",
  initialCount = 60 // Default countdown time in seconds
}) => {
  const [count, setCount] = useState(() => {
    const savedCount = localStorage.getItem("otpTimer");
    return savedCount ? parseInt(savedCount, 10) : initialCount;
  });

  useEffect(() => {
    if (count === 0) {
      setDisableSend(false);
      localStorage.removeItem("otpTimer");
      return;
    }

    setDisableSend(true);
    localStorage.setItem("otpTimer", count);

    const timer = setTimeout(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [count, setDisableSend]);

  const handleResend = () => {
    if (count === 0) {
      setDisableSend(true);
      setCount(initialCount);
      localStorage.setItem("otpTimer", initialCount);
      resendOtp();
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <span
      className={`${
        count === 0 ? "text-alat-light font-bold cursor-pointer" : "text-gray-700 cursor-not-allowed"
      }`}
      onClick={() => {
        if(count === 0){
          handleResend()
        }
      }}
    >
      {text}{" "}
      {count === 0 ? (
        <></>
      ) : (
        `in ${formatTime(count)}s`
      )}
    </span>
  );
};

export default OtpRateLimiter;

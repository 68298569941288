import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { TiSocialLinkedinCircular } from "react-icons/ti";

import LogoWhite from "../../assets/img/logo-white.png";
import CBNLogo from "../../assets/img/cbn.png";

const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer
    className="relative overflow-hidden bg-black text-white mt-10"
    data-testid="footer"
  >
    <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-wrap -mx-3 border-b-2 border-alat-lightGrey pb-10 md:justify-between">
        <div className="w-full p-4 md:px-3 md:w-1/6 sm:w-1/3">
          <Link to="/merchant-profile">
            <span className="sr-only">ALATpay</span>
            <img className="h-auto w-20" src={LogoWhite} alt="White Logo" />
          </Link>

          <div className="mt-4 flex items-center space-x-2">
            <p className="text-base text-alat-toggleWhite font-semibold">Licensed by the CBN </p>
            <img src={CBNLogo} alt="Licensed by the CBN" />
          </div>
          <p className="mt-4 text-sm">alatpaysupport@wemabank.com</p>
          <p className="mt-4 text-sm w-3/5 mdx2:w-auto break-normal">
            8, Idowu Taylor Street, <br className="mdx2:hidden" />
            VI, Lagos State.
          </p>
        </div>

        <div className="w-full p-4 md:px-3 md:w-1/6 sm:w-1/3">
          <h4 className="mb-6 font-bold">Quick Links</h4>
          <a
            href={`${window.location.origin}/#how-it-works`}
            className="text-sm block mb-2"
          >
            How it Works
          </a>
          <a
            href={`${window.location.origin}/#why-alatpay`}
            className="text-sm block mb-2"
          >
            Why ALATpay
          </a>
          <a href={`${window.location.origin}/pricing`} className="text-sm block mb-2">
            Pricing
          </a>
          <a
            href={process.env.REACT_APP_DOC_URL}
            className="text-sm block mb-2"
          >
            Developer
          </a>
        </div>

        <div className="w-full p-4 md:px-3 md:w-1/6 sm:w-1/3">
          <h4 className="mb-6 font-bold">Legal</h4>
          <a
            href="/terms-and-conditions"
            target="_target"
            rel="noreferrer noopener"
            className="text-sm block mb-2"
          >
            Terms and Condition
          </a>
          <a
            href="/privacy-policy"
            target="_target"
            rel="noreferrer noopener"
            className="text-sm block mb-2"
          >
            Privacy Policy
          </a>
        </div>

        <div className="w-1/2 p-4 md:px-3 md:w-1/6 sm:w-1/3">
          <h4 className="mb-3 font-bold">Connect with us:</h4>
          <div className="flex items-center space-x-2">
            <a
              href="https://www.facebook.com/alatpay_ng"
              target="_target"
              rel="noreferrer noopener"
              className="text-sm block mb-2"
            >
              <FaFacebook size="27" />
            </a>
            <a
              href="https://www.instagram.com/alatpay_ng"
              target="_target"
              rel="noreferrer noopener"
              className="text-sm block mb-2"
            >
              <FaInstagram size="25" />
            </a>
            <a
              href="https://www.linkedin.com/alatpay_ng"
              target="_target"
              rel="noreferrer noopener"
              className="text-sm block mb-2"
            >
              <TiSocialLinkedinCircular size="35" />
            </a>
            <a
              href="https://www.twitter.com/alatpay_ng"
              target="_target"
              rel="noreferrer noopener"
              className="text-sm block mb-2"
            >
              <AiFillTwitterCircle size="30" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p className="text-center text-xs font-bold py-4 pb-6">
        &copy; {`${currentYear}`} ALAT - All rights reserved.
      </p>
    </div>
  </footer>
);

export default Footer;

import { create } from "zustand";
import { ONBOARDINGSTAGE, defaultShareHolder } from "../../data/onboardingData";
import { persist, createJSONStorage } from "zustand/middleware";
import { isValidEmail } from "../../utilities/general";

const initialState = {
  step: ONBOARDINGSTAGE.BUSINESS_DETAILS,
  email: "",
  phoneNumber: "",
  businessName: "",
  businessId: "",
  locations: [],
  states: [],
  cities: [],
  lgas: [],
  lcdas: [],
  countries: [],
  shareholders: [defaultShareHolder],
  shareholdersErrors: [null],
  merchantDetails: {},
  userNINDetails: {},
  merchantFullName: ""
};

const useOnboardingStore = create(
  persist(
    (set) => ({
      ...initialState,
      resetOnboardingState: () => {
        set((state) => {
          return {
            ...state,
            ...initialState
          };
        });
      },
      setLocations: (location) => {
        set((state) => {
          return {
            ...state,
            locations: location
          };
        });
      },
      setStates: (location) => {
        set((state) => {
          return {
            ...state,
            states: location
          };
        });
      },
      setCountries: (location) => {
        set((state) => {
          return {
            ...state,
            countries: location
          };
        });
      },
      setCity: (location) => {
        set((state) => {
          return {
            ...state,
            cities: location
          };
        });
      },
      setLgas: (location) => {
        set((state) => {
          return {
            ...state,
            lgas: location
          };
        });
      },
      setLcdas: (location) => {
        set((state) => {
          return {
            ...state,
            lcdas: location
          };
        });
      },
      setEmail: (email) => {
        set((state) => {
          return {
            ...state,
            email: email
          };
        });
      },
      setPhoneNumber: (phoneNumber) => {
        set((state) => {
          return {
            ...state,
            phoneNumber
          };
        });
      },
      setBusinessName: (businessName) => {
        set((state) => {
          return {
            ...state,
            businessName: businessName
          };
        });
      },
      setBusinessId: (businessId) => {
        set((state) => {
          return {
            ...state,
            businessId: businessId
          };
        });
      },
      setStep: (step) => {
        set((state) => {
          return {
            ...state,
            step: step
          };
        });
      },
      resetShareholders: () => {
        set((state) => {
          return{
            ...state,
            shareholders:[defaultShareHolder]
          }
        })
      },
      addShareholder: () => {
        set((state) => {
          const newShareholders = [...state.shareholders, defaultShareHolder];
          return {
            ...state,
            shareholders: newShareholders,
            shareholdersErrors: state.shareholdersErrors
          };
        });
      },
      deleteShareholder: (sIndex) => {
        set((state) => {
          const newShareholders = state.shareholders.filter(
            (item, index) => index !== sIndex
          );
          return {
            ...state,
            shareholders: newShareholders,
            shareholdersErrors: state.shareholdersErrors
          };
        });
      },
      updateShareholder: (shareHolderIndex, updatedData) => {
        set((state) => {
          const newShareholders = state.shareholders.map((shareholder, index) =>
            index === shareHolderIndex
              ? { ...shareholder, ...updatedData }
              : shareholder
          );
          return {
            ...state,
            shareholders: newShareholders,
            shareholdersErrors: state.shareholdersErrors
          };
        });
      },
      setBusinessVerificationStatus: (status) => {
        set((state) => ({
          ...state,
          businessVerificationStatus: status
        }));
      },
      
      validateShareholders: () => {
        set((state) => {
          const sumOfShares = state.shareholders.reduce((sum, shareholder) => {
            return sum + (parseInt(shareholder.sharesowned) || 0);
          }, 0);
          
          const errors = state.shareholders.map((item, index) => {
            let errorObject = {};
            const duplicateEmail = state.shareholders.filter((dup)=>item.email === dup.email).length > 1;
            const duplicatePhonenumber = state.shareholders.filter((dup,index)=>item.phonenumber === dup.phonenumber).length > 1;
            const duplicateBvn = state.shareholders.filter((dup)=>item.shareholderbvn === dup.shareholderbvn).length > 1;
            
            if (item?.firstname?.length < 2) {
              errorObject = {
                ...errorObject,
                firstname: "Firstname is required"
              };
            }
            if (item?.lastname?.length < 2) {
              errorObject = {
                ...errorObject,
                lastname: "Lastname is required"
              };
            }
            if (!item?.sharesowned) {
              errorObject = {
                ...errorObject,
                sharesowned: "Shares information is required"
              };
            }
            if (parseInt(item?.sharesowned) < 5) {
              errorObject = {
                ...errorObject,
                sharesowned: "Shares must be greater than 5%"
              };
            }
            if ( sumOfShares > 100) {
              errorObject = {
                ...errorObject,
                sharesowned: "Total Shares must be less than 100%"
              };
            }
            if (!item?.shareholderbvn) {
              errorObject = {
                ...errorObject,
                shareholderbvn: "BVN is required"
              };
            }
            if (item?.shareholderbvn?.length !== 11) {
              errorObject = {
                ...errorObject,
                shareholderbvn: "Enter a valid BVN"
              };
            }
            if (item?.idFile === null) {
              errorObject = {
                ...errorObject,
                idFile: "Upload your ID"
              };
            }
            if (item?.idType === 0) {
              errorObject = {
                ...errorObject,
                idType: "Select an ID type"
              };
            }
            if(duplicateBvn){
              errorObject = {
                ...errorObject,
                shareholderbvn: "Duplicate BVN not allowed"
              };
            }
            if(duplicatePhonenumber && item.phonenumber !== ""){
              errorObject = {
                ...errorObject,
                phonenumber: "Duplicate Phone number not allowed"
              };
            }
            if(duplicateEmail && item.email !== ""){
              errorObject = {
                ...errorObject,
                email: "Duplicate Email not allowed"
              };
            }
            if(!item?.isBvnValidated && item?.shareholderbvn?.length === 11){
              errorObject = {
                ...errorObject,
                shareholderbvn: "Invalid BVN"
              };
            }
            if(!item?.email !== ""){
              if(!isValidEmail(item?.email)){
                errorObject = {
                  ...errorObject,
                  email: "Enter a valid email"
                };
              }
            }
            if (Object.keys(errorObject).length === 0) return null;
            return errorObject;
          });

          return {
            ...state,
            shareholders: state.shareholders,
            shareholdersErrors: errors
          };
        });
      },
      setMerchantDetails: (payload) => {
        set((state) => ({
          ...state,
          merchantDetails: payload
        }));
      },
      setUserNINDetails: (payload) => {
        set((state) => ({
          ...state,
          userNINDetails: payload
        }));
      },
      setMerchantFullName: (payload) => {
        set((state) => ({
          ...state,
          merchantFullName: payload
        }));
      }
    }),

    {
      name: "onboarding-storage",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

export default useOnboardingStore;

import Axios from "axios";
import paths from "./endpoints";

import { decodeToken, encodeToken, logout } from "../utilities/auth";
import { Log } from "../utilities/general";

const fetchBackend = async (
  endpoint,
  extra,
  method,
  auth,
  body,
  pQuery,
  param,
  multipart,
  responseType,
  nonBaseURL,
  subscriptionKey,
  shouldLogUserOut,
  useDefaultSubKey
) => {
  const singleBusiness = decodeToken("sb", '/portal', false);
  const subKey = singleBusiness?.subscriptionPrimaryKey || singleBusiness?.subscriptionSecondaryKey || '';

  const headers = {
    "Ocp-Apim-Subscription-Key": useDefaultSubKey ? process.env.REACT_APP_APIM_SUB_KEY : subscriptionKey || subKey,
    "Content-Type": multipart ? "multipart/form-data" : "application/json"
  };
  const path = paths[endpoint] || endpoint;
  let url = nonBaseURL || `${process.env.REACT_APP_API_BASE_URL}/${path}`;

  if (extra) {
    url += `${extra}`;
  }

  if (param) {
    url += `/${param}`;
  }

  if (pQuery) {
    let paramsArray = Object.keys(pQuery).map(
      (key) =>
        pQuery[key] &&
        `${encodeURIComponent(key)}=${encodeURIComponent(pQuery[key])}`
    );

    paramsArray = paramsArray.filter((item) => item);
    url += `?${paramsArray.join("&")}`;
  }

  // if (auth) {
  //   headers.Authorization = `Bearer ${decodeToken("t")}`;
  // }

  const options = {
    url,
    method,
    headers,
    withCredentials: true
  };

  if (responseType) {
    options.responseType = responseType;
  }

  if (body) {
    options.data = body;
  }

  Log(options);

  Axios.interceptors.response.use(
    (response) => {
      if(path.includes("login") || path.includes("refresh-token")){
        const currentTime = Date.now();
        const expirationTime = currentTime + 60 * 5 * 1000;
        encodeToken('timer', expirationTime);
      }
      return response;
    },
    (err) => {
      if (err.response) {
        if (err.response.status === 401) {
          Log("UNAUTHORIZED REQUEST...");
          if(path.includes("refresh-token")){
            logout("/login");
          }
          // auth && shouldLogUserOut && logout("/login");
        } else if (err.response.status === 500) {
          // Return a default response for status 500
          Log("INTERNAL SERVER ERROR...");
          return {
            status: 500,
            data: {
              message: "An error occurred. Please try again later."
            }
          };
        }
      }
      Log(err.response);
      return err.response || {
        status: 500,
        data: {
          message: "An unknown error occurred. Please try again later."
        }
      };
    }
  );
  
  return Axios(options).then(
    (res) => res,
    async (err) => {
      if (err && err.response && err.response.status === 401) {
        // if businessIsNotLive return;
        // log the user out and return
        Log("UNAUTHORIZED REQUEST...");
        auth && shouldLogUserOut && logout("/login");
      }
      Log(err.response);
      return err.response;
    }
  );
};

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} pQuery
 * @param {string} param
 * @param {boolean} auth
 * @param {string} responseType
 * @param {string} nonBaseURL
 * @param {boolean} shouldLogUserOut
 * @param {boolean} useDefaultSubKey
 */
export const get = ({
  endpoint,
  extra = null,
  pQuery,
  param = null,
  auth = true,
  responseType = null,
  nonBaseURL = null,
  subscriptionKey = null,
  shouldLogUserOut = true,
  useDefaultSubKey = false
}) =>
  fetchBackend(
    endpoint,
    extra,
    "GET",
    auth,
    null,
    pQuery,
    param,
    null,
    responseType,
    nonBaseURL,
    subscriptionKey,
    shouldLogUserOut,
    useDefaultSubKey
  );

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} body
 * @param {boolean} auth
 * @param {boolean} multipart
 * @param {string} responseType
 * @param {string} nonBaseURL
 * @param {boolean} shouldLogUserOut
 * @param {boolean} useDefaultSubKey
 */
export const post = ({
  // eslint-disable-next-line max-len
  endpoint,
  extra = null,
  body,
  auth = true,
  multipart = false,
  responseType = null,
  nonBaseURL = null,
  subscriptionKey = null,
  pQuery,
  shouldLogUserOut = true,
  useDefaultSubKey = false
}) =>
  fetchBackend(
    endpoint,
    extra,
    "POST",
    auth,
    body,
    pQuery,
    null,
    multipart,
    responseType,
    nonBaseURL,
    subscriptionKey,
    shouldLogUserOut,
    useDefaultSubKey,
  );

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} body
 * @param {string} param
 * @param {boolean} auth
 * @param {boolean} multipart
 * @param {string} nonBaseURL
 * @param {string} subscriptionKey
 * @param {boolean} shouldLogUserOut
 * @param {boolean} useDefaultSubKey
 */
export const put = ({
  endpoint,
  extra = null,
  body,
  param,
  auth = true,
  multipart = false,
  nonBaseURL = null,
  subscriptionKey = null,
  shouldLogUserOut = true,
  useDefaultSubKey = false
}) =>
  fetchBackend(
    endpoint,
    extra,
    "PUT",
    auth,
    body,
    null,
    param,
    multipart,
    null,
    nonBaseURL,
    subscriptionKey,
    shouldLogUserOut,
    useDefaultSubKey
  );

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} body
 * @param {string} param
 * @param {boolean} auth
 * @param {boolean} multipart
 * @param {string} nonBaseURL
 * @param {string} subscriptionKey
 * @param {boolean} shouldLogUserOut
 * @param {boolean} useDefaultSubKey
 */
export const patch = ({
  endpoint,
  extra = null,
  body,
  param,
  auth = true,
  multipart = false,
  nonBaseURL = null,
  subscriptionKey = null,
  shouldLogUserOut = true,
  useDefaultSubKey = false
}) =>
  fetchBackend(
    endpoint,
    extra,
    "PATCH",
    auth,
    body,
    null,
    param,
    multipart,
    null,
    nonBaseURL,
    subscriptionKey,
    shouldLogUserOut,
    useDefaultSubKey
  );

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} body
 * @param {string} param
 * @param {boolean} auth
 * @param {string} nonBaseURL
 * @param {string} subscriptionKey
 * @param {boolean} shouldLogUserOut
 * @param {boolean} useDefaultSubKey
 */
export const del = ({
  endpoint,
  param,
  auth = true,
  extra = null,
  body = null,
  nonBaseURL = null,
  subscriptionKey = null,
  shouldLogUserOut = true,
  useDefaultSubKey = false
}) =>
  fetchBackend(
    endpoint,
    extra,
    "DELETE",
    auth,
    body,
    null,
    param,
    false,
    null,
    nonBaseURL,
    subscriptionKey,
    shouldLogUserOut,
    useDefaultSubKey
  );

import React, { Fragment, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import { Popover, Transition } from '@headlessui/react';

import Logo from '../../assets/img/logo.png';

const navigation = [
  { name: 'Why ALATpay', href: '/#why-alatpay' },
  { name: 'How it Works', href: '/#how-it-works' },
  { name: 'Pricing', href: '/pricing' },
  { name: 'Developer', href: process.env.REACT_APP_DOC_URL },
  { name: 'Talk to Sales', href: '/talk-to-sales' }
];

const Navbar = () => {
  const { hash, pathname } = useLocation();
  const whyRef = useRef();
  const howRef = useRef();
  // const pricingRef = useRef();

  useEffect(() => {
    document.title = 'ALATpay - Home';
    scrollToSection();
  }, [hash]);

  const scrollToSection = () => {
    if (window.location.hash === '#why-alatpay') {
      whyRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (window.location.hash === '#how-it-works') {
      howRef.current.scrollIntoView({ behavior: 'smooth' });
    } 
    // else if (window.location.hash === '#pricing') {
    //   pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
  };

  return (
    <section className="max-w-7xl mx-auto font-inter z-10">
      <Popover>
        {({ open }) => (
          <>
            <div className="relative bg-transparent py-4 mdx2:py-6 px-4 sm:px-6">
              <nav
                className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                aria-label="Global"
              >
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full mdx2:w-auto">
                    <Link to="/">
                      <span className="sr-only">ALATpay</span>
                      <img
                        className="h-8 w-auto sm:h-10"
                        src={Logo}
                        alt="Logo"
                      />
                    </Link>
                    <div className="-mr-2 flex items-center mdx2:hidden">
                      <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center focus:outline-none">
                        <span className="sr-only">Open nav menu</span>
                        <FiMenu className="h-8 w-8" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden mdx2:flex w-full items-center justify-between">
                  <div className="inline-block ml-10 pr-4 space-x-8">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={scrollToSection}
                        className={`font-medium text-sm hover:text-alat-light 
                        ${pathname===item.href ? "text-alat-light" : "text-black"}`}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="flex justify-center">
                    <div className="rounded-md">
                      <Link
                        to="/login"
                        className="w-full flex items-center justify-center px-5 py-2 border border-transparent text-base font-normal rounded-md hover:text-alat-light"
                      >
                        Login
                      </Link>
                    </div>
                    <div className="rounded-md shadow mt-3 sm:mt-0 sm:ml-3">
                      <Link
                        to="/merchant-signup"
                        className="w-full flex items-center justify-center px-5 py-2 border border-alat-light text-base font-normal rounded-md text-alat-light hover:bg-alat-light hover:text-white"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </nav>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right mdx2:hidden z-10"
              >
                {({ close }) => (
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src={Logo}
                          alt="Logo"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="rounded-md p-0 inline-flex items-center justify-center focus:outline-none">
                          <span className="sr-only">Close nav menu</span>
                          <FiX className="h-8 w-8" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="px-3 pt-2 pb-3 space-y-1">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={() => {
                            close();
                            scrollToSection();
                          }}
                          className="block px-3 py-2 rounded-md text-base font-medium text-alat-alatBlack hover:text-alat-dark hover:bg-alat-light-hover"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="max-w-xs mx-auto flex px-2 pb-3">
                      <div className="rounded-md w-6/12 mb-3">
                        <Link
                          to="/login"
                          className="w-full flex items-center justify-center px-5 py-2 border border-transparent text-base font-normal rounded-md hover:text-alat-dark"
                        >
                          Login
                        </Link>
                      </div>
                      <div className="rounded-md shadow w-6/12 mb-3 ml-3">
                        <Link
                          to="/merchant-signup"
                          className="w-full flex items-center justify-center px-5 py-2 border border-alat-dark text-base font-normal rounded-md text-alat-dark hover:bg-alat-light"
                        >
                          Get Started
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </section>
  );
};

export default Navbar;

const privacySideBarLinks = [
  {
    title: "General Definitions",
    link: "#general-definitions"
  },
  {
    title: "Cookies",
    link: "#cookies"
  },
  {
    title: "Internet Communications",
    link: "#internet-communications"
  },
  {
    title: "Your Rights",
    link: "#your-rights"
  },
  {
    title: "Contacting you",
    link: "#contacting-you"
  }
];

export default privacySideBarLinks;

import React, {
  useReducer, createContext, useContext
} from 'react';
import { reducer } from './reducer';
import { defaultShareHolder } from '../../data/onboardingData';

// Object Shared State Default Store
const INITIAL_STATE = {
  currentApiKey: '',
  email: '',
  userData: '',
  accountVerified: false,
  accountName: '',
  allBusinesses: [],
  business: {},
  allCustomers: 0,
  totalSettlements: 0,
  shareholders: [defaultShareHolder],
  shareholdersErrors:[null]
};

// Create context here
export const AppContext = createContext();

// Set context info
export const AppProvider = ({ children }) => {
  const [appContext, setAppContext] = useReducer(reducer, INITIAL_STATE);
  return (
    <AppContext.Provider
      value={{
        appContext,
        setAppContext
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Export the context state
export const useAppContext = () => useContext(AppContext);

// the above ia just a way of simplyfying things so that in the
// respective components you can just type useAppContext()
// instead of setting the usecontext again

/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { encodeToken } from '../../utilities/auth';
import {
  CURRENT_USER_EMAIL, ACCOUNT_NAME, ACCOUNT_VERIFIED, USER_NAME, SELECTED_BUSINESS, ALL_BUSINESSES,
  ALL_CUSTOMERS, TOTAL_SETTLEMENTS, USER_DATA, ONBOARDING_SHAREHOLDERS, SHAREHOLDERS_ERRORS
} from './appTypes';

// Reducer to change state
export const reducer = (state, action) => {
  switch (action.type) {
  case CURRENT_USER_EMAIL:
    return {
      ...state,
      email: action.payload
    };
  case ACCOUNT_NAME:
    return {
      ...state,
      accountName: action.payload
    };
  case ACCOUNT_VERIFIED:
    return {
      ...state,
      accountVerified: action.payload
    };
  case USER_NAME:
    return {
      ...state,
      userName: action.payload
    };
  case SELECTED_BUSINESS:
    const apiKey = action?.payload?.subscriptionPrimaryKey || action?.payload?.subscriptionSecondaryKey || '';
    encodeToken("sub", apiKey, "session");
    
    return {
      ...state,
      business: action.payload,
      currentApiKey: apiKey
    }
  case ALL_BUSINESSES:
    return {
      ...state,
      allBusinesses: action.payload
    };
  case ALL_CUSTOMERS:
    return {
      ...state,
      allCustomers: action.payload
    };
  case TOTAL_SETTLEMENTS:
    return {
      ...state,
      totalSettlements: action.payload
    };
  case USER_DATA:
    return {
      ...state,
      userData: action.payload
    };
  case ONBOARDING_SHAREHOLDERS:
    return {
      ...state,
      shareholders: action.payload
    }
  case SHAREHOLDERS_ERRORS:
    return {
      ...state,
      shareholdersErrors: action.payload
    }
  default:
    throw new Error(`Unknown action: ${action.type}`);
  }
};
